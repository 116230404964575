<template>
    <div>
        <h2 class="text-secondary">{{ theme.pageTitle }}</h2>

        <div class="row mb-5">
            <div class="col-12 col-lg-7 order-1 order-lg-0">

                <div v-html="theme.content"></div>

            </div>
            <div class="col-12 col-lg-5 order-0 order-lg-1">

                <Video :embed="theme.videoEmbed" v-if="theme.videoEmbed" />

            </div>
        </div>

        <div class="text-right">
            <router-link class="btn btn-secondary" :to="{ path: `/analyse/${theme.slug}/scan`}">{{ buttonTitle
                }}<span><icon
                        icon="arrow-right"/></span></router-link>
        </div>
    </div>

</template>

<script>
    import Icon from "@/components/Icon";
    import Video from '@/components/Video.vue';

    export default {
        name: 'Digitale Tool',
        components: {
            Icon,
            Video
        },
        data() {
            return {
                buttonTitle: 'Start analyse',
                theme: {},
            }
        },
        methods: {
            toggleClass(index) {
                this.themes[index].isActive = !this.themes[index].isActive;
            }
        },
        created() {
            this.theme = this.$store.getters.getThemeBySlug(this.$route.params.thema);
        },
        mounted() {
            const breadcrumbs = [
                {
                    'label': 'Home',
                    'path': '/'
                },
                {
                    'label': 'Analyse',
                    'path': '/analyse/'
                },
                {
                    'label': this.theme.pageTitle,
                },
            ];
            this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});
        },
    }
</script>
